import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
// import styled from 'styled-components';
import { PostItem, NarrativeDetails, Layout } from '../components';
import { Container, Column, PostsWrapper } from '../styles/shared';

const Narrative = ({
  data: {
    narrative: { edges: narrativeEdges },
  },
}) => {
  const [clicked, setClicked] = React.useState('');

  const handleClick = (e) => setClicked(e.currentTarget.id);

  return (
    <Layout>
      <Container>
        <Column>
          <PostsWrapper>
            {narrativeEdges.map(({ node }, idx) => (
              <PostItem
                key={idx}
                clicked={clicked}
                handleClick={handleClick}
                path={node.fields.slug}
                thumbnail={node.frontmatter.thumbnail.childImageSharp.fluid}
                title={node.frontmatter.title}
                link={node.frontmatter.link}
              >
                <NarrativeDetails
                  title={node.frontmatter.title}
                  year={node.frontmatter.year}
                  director={node.frontmatter.director}
                  editor={node.frontmatter.editor}
                  writer={node.frontmatter.writer}
                  starring={node.frontmatter.starring}
                  timeLength={node.frontmatter['length']}
                  winner={node.frontmatter.winner}
                />
              </PostItem>
            ))}
          </PostsWrapper>
        </Column>
      </Container>
      <script src="https://player.vimeo.com/api/player.js" />
    </Layout>
  );
};

export default Narrative;

Narrative.propTypes = {
  data: PropTypes.shape({
    narrative: PropTypes.shape({
      edges: PropTypes.array.isRequired,
    }),
  }).isRequired,
};

export const pageQuery = graphql`
  query NarrativeQuery {
    narrative: allMarkdownRemark(
      limit: 4
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { fields: { sourceInstanceName: { eq: "narrative" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            year
            length
            link
            writer
            director
            editor
            starring
            winner
            thumbnail {
              childImageSharp {
                fluid(
                  maxWidth: 1000
                  quality: 90
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
